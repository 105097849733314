





























import { IOption } from "@interface/common.interface";
import Vue from "vue";
import { accountingCurrencyService } from "@service/accounting-currency.service";
import { ResponseListAccountingCurrency, ResponseAccountingCurrency } from "@interface/currency.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import MNotificationVue from "@/mixins/MNotification.vue";
import { debounceProcess } from "@/helpers/debounce";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";

export default Vue.extend({
  name: "CSelectCurrency",
  mixins: [
    MNotificationVue,
  ],
  props: {
    value: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    propDefault: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    this.searchCurrency = debounceProcess(this.searchCurrency, 300);
    return {
      dtOpt: [] as IOption<ResponseAccountingCurrency>[],
      loading: false,
    };
  },
  created() {
    this.searchCurrency({search: ""});
  },
  methods: {
    getListCurrency(params: RequestQueryParamsModel): Promise<ResponseListAccountingCurrency> {
      return accountingCurrencyService.listOfMasterCurrency(params);
    },
    onSelect(e: string, meta: ResponseAccountingCurrency): void {
      this.$emit("input", e);
      this.$emit("on-select", { value: e, meta });
    },
    async searchCurrency({search}: {search?: string, init?: boolean}): Promise<void> {
      try {
        this.loading = true;
        const params: RequestQueryParamsModel = {
          limit: DEFAULT_PAGE_SIZE,
          page: 0,
        };
        if (search) params.search = `currencyCode~*${search}*_OR_description~*${search}`;
        const res = await this.getListCurrency(params);
        this.dtOpt = res.data.map(x => ({ key: x.currencyCode, value: x.id, meta: x }));
        this.setDefault();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    setDefault() {
      const idr = this.dtOpt.find(x => x.key === "IDR");
      if (idr && this.propDefault) {
        this.onSelect(idr.value, idr.meta as ResponseAccountingCurrency);
      }
    },
  }
});

